
import { h } from "hyperapp";
import { map, filter } from "lodash";
import { ExportToCsv } from 'export-to-csv';

const api = require('../services/api');

const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalseparator: '.',
  showLabels: true,
  showTitle: true,
  useBom: true,
  filename: 'export des users pour que jack puisse les importer partout où il veut ok.',
  useKeysAsHeaders: true,
};

const csvExporter = new ExportToCsv(options);

const view = (state, actions) => props => {
  return (
  <div class="deepsea-mainpage" oncreate={element => actions.getData()}>
			<div style="margin-top: 75px; margin-left:50px; margin-bottom: 50px"></div>
			<div class="btn export-btn" onclick={() => actions.exportCSV(state.users)}>Export CSV</div>
      <div class="old_ie_wrapper">
        <table class="fixed_headers">
          <thead>
            <tr>
              <th class="table-name">Name: {state.users.length}</th>
							<th class="table-name">Type</th>
							<th class="table-mail">Email</th>
							<th class="table-view">Total Views</th>
							<th class="table-view">Total Projects</th>
              <th class="table-view">Created</th>
              <th class="table-view">Deleted</th>
							<th class="table-link">Link to project</th>
              <th class="table-view">Views: {state.views}</th>
							<th class="table-view">Likes</th>
							<th class="table-view">Comments</th>
            </tr>
          </thead>
          <tbody>
            {state.users.map(user => (
              <tr>
                <td class="table-name">{user.name}</td>
								<td class="table-name">{user.type}</td>
								<td class="table-mail">{user.email}</td>
								<td class="table-view" style="text-align:center">{user.totalViews}</td>
								<td class="table-view" style="text-align:center">{user.projects.length}</td>
								<td class="table-view" style="text-align:center">{user.projectcreated}</td>
                <td class="table-view" style="text-align:center">{user.projectdeleted}</td>
								<td class="table-link" style="float: left;">
									{user.projects.map(project => (
										!project.published ? null :
											<a href={'https://harbor.naker.io/' + project._id} class="linktoproject projectpublished" target='_blank'>{project.name}</a>
									))}
								</td>
								<td class="table-view">
									{user.projects.map(project => (
										!project.published ? null :
											<div class="deepsea-views">{project.views ? project.views : 0}</div>
									))}
								</td>
								<td class="table-view">
									{user.projects.map(project => (
										!project.published ? null :
											<div class="deepsea-views">{project.social ? project.social.likes.length : 0}</div>
									))}
								</td>
								<td class="table-view">
									{user.projects.map(project => (
										!project.published ? null :
											<div class="deepsea-views">{project.social ? project.social.comments.length : 0}</div>
									))}
								</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    )
}

const state = {
  views: 0,
  users: [],
  projects: [],
}

const actions = {

  getData: () => (state, actions) => {
    api.getToken();
		api.get('deepsea/all', '', (data) => {
      if (data.success != undefined) {
        api.disconnect();
      } else {
        actions.setData(data)
      }
    });
	},

	sortUsers: () => (state, actions) => {
		let users = state.users;
		users.sort(((a, b) => { return (a.totalViews > b.totalViews) ? 1 : ((b.totalViews > a.totalViews) ? -1 : 0); }));
		users.reverse();
		actions.setUsers(users);
	},

  setData: value => (state, actions) => {
    let users = map(value.users, (user) => {
			let userproject = filter(value.projects, (o) => { return o.designer == user._id; });
			user.totalViews = 0;
			value.projects.map((project) => {
				if (project.designer == user._id)
					if (project.views)
						user.totalViews += Number(project.views);
			});
			user.projects = userproject;
      return user;
    });

		let views = 0;
    map(value.projects, (project) => {
      if (project.views) views += project.views;
		});
    actions.setProjects(value.projects);
		actions.setUsers(users);
		actions.sortUsers();
    actions.setViews(views);
  },

  setUsers: value => state => ({ users: value }),
  setProjects: value => state => ({ projects: value }),
  setViews: value => state => ({ views: value }),

  exportCSV: (users) => (state, actions) => {
    for (var i = 0; i < users.length; i++) {
      users[i].projects = users[i].projects.length;
      if (!users[i].type) users[i].type = '';
      if (!users[i].company) users[i].company = '';
      if (!users[i].projectcreated) users[i].projectcreated = 0;
			if (!users[i].projectdeleted) users[i].projectdeleted = 0;
		}
    csvExporter.generateCsv(users);
  },

}

const Data = {};
Data.view = view;
Data.state = state;
Data.actions = actions;

module.exports = Data;
