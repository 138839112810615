
import { h } from "hyperapp";
import { Link, Route } from "@hyperapp/router";
import Cookies from 'js-cookie';

const api = require('../services/api');
const spyservice = require('../services/spyservice');
const nakerscene = require('../services/nakerscene');

const Project = require('./project');
const Account = require('./account');

const Teams = () => <h3>Teams</h3>
const Billing = () => <h3>Billing</h3>

const view = (state, actions) => props => {
	let match = props.match;
	let location = props.location;
	if (match == undefined) return;
	actions.initUser();
	actions.checkPearl(state);
	if (state.user == null) return;
	return (
		<div>
			<div class="background"></div>
			<div class="left-menu" oncreate={element => actions.showPearl(state)}>
				<div class="pearl-container transparent-container">
					<i class='main-icon' id="toppearl"></i>
					<Link class={(location.pathname.indexOf('projects') != -1) ? "backtodashboard btn btn-third" : "hide"} to={`/dashboard/account`}><i class="icon-intalemenu"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></Link>
					<Link class={(location.pathname.indexOf('account') != -1) ? "backtodashboard btn btn-third" : "hide"} to={`/dashboard/projects`}><i class="icon-back"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></Link>
					<div class="dashboard-text">{state.user.name}</div>
				</div>
			</div>
			<Route parent path={`${match.path}/projects`} render={Project.view(state, actions.project)} />
			<Route parent path={`${match.path}/account`} render={Account.view(state, actions)} />
			<Route parent path={`${match.path}/team`} render={Teams} />
			<Route parent path={`${match.path}/billing`} render={Billing} />
		</div>
	)
}

let pearl;

const state = {
	page: '',
	user: null,
	loadinguser: false,
}

state.project = Project.state;
state.account = Account.state;

const actions = {

	showPearl: (mainstate) => {
		let color = mainstate.user.pearlcolor;
		let pearlColor = (color && color.length != 0) ? color : [255, 255, 255];
		pearl = nakerpearl.render({ container: document.getElementById('toppearl'), model: 'pearl', modelFollowMouseRapidity: 2 }, (pearl) => {
			if (mainstate.user.pearl != undefined) {
				if (mainstate.user.pearl.length != 0) {
					pearl.model.pattern.mesh._setPositions(mainstate.user.pearl);
				}
			}
			pearl.light.setColor(pearlColor);
			pearl.light.setIntensity(1.5);
			pearl.light.show();
		});
	},

	checkPearl: (mainstate) => (state, actions) => {
		if (pearl) {
			if (mainstate.user.pearl != pearl.model.pattern.mesh._getPositions()) {
				pearl.model.pattern.mesh._setPositions(mainstate.user.pearl);
			}
			if (mainstate.user.pearlcolor != pearl.light.color) {
				pearl.light.setColor(mainstate.user.pearlcolor);
			}
			pearl.model.pattern.setMaterialProperties({ roughness: nakerscene.metallicroughness, metallic: nakerscene.metallicroughness });
		}
	},

	initUser: () => (state, actions) => {
		if (state.loadinguser) return;
		actions.loading();
		let userCookie = Cookies.get('token');
		if (userCookie == undefined) return window.location = '/login';
		api.getToken();
		api.get('user', {}, (data) => {
			if (data.success) {
				api.disconnect();
			} else {
				actions.setUser(data);
				spyservice.identify(data)
			}
		});
	},

	loading: value => state => ({ loadinguser: true }),
	setUser: value => state => ({ user: value }),
}

actions.project = Project.actions;
actions.account = Account.actions;


const Dashboard = {};
Dashboard.view = view;
Dashboard.actions = actions;
Dashboard.state = state;

module.exports = Dashboard;
