
import { h } from "hyperapp";
import { Link } from "@hyperapp/router";

const api = require('../services/api');
const nakerscene = require('../services/nakerscene');

const view = (state, actions) => props => (
	<div class="siimple-grid" oncreate={element => { actions.testToken(); element.focus(); }}>
		<div class="siimple-grid-row">
			<div class="login-wrapper siimple-grid-col siimple-grid-col--4 siimple-grid-col--xl-6 siimple-grid-col--md-12">
				<div class="login-form transparent-container">

					<div class={!state.token && !state.success ? "full-width" : "hide"}>
						<h1 class="full-width thin">Enter your E-mail to reset your Password :</h1>
						<div class="form-group">
							<input
								type='text'
								placeholder=" "
								value={state.email}
								oninput={e => actions.updateEmail(e.target.value)}
								onkeypress={(e) => actions.keypressEmail(e)}
							/>
							<span class="label">E-mail</span>
							<span class="border"></span>
						</div>
						<div class="checkbox-container">
							<Link to="/login" >Back to Log In</Link>
						</div>
						<div class="checkbox-container">
							<div class={state.error ? "colorerror" : "hide"}>{state.error}</div>
						</div>
						<div class="button-area">
							<button class="btn btn-primary" onclick={() => actions.sendEmail()}>RESET</button>
						</div>
					</div>

					<div class={state.token && !state.success ? "full-width" : "hide"}>
						<h1>What is the new password you want?</h1>
						<div class="form-group">
							<input
								type='password'
								placeholder=" "
								value={state.password}
								oninput={e => actions.updatePassword(e.target.value)}
								onkeypress={(e) => actions.keypressPassword(e)}
							/>
							<span class="label">New Password</span>
							<span class="border"></span>
						</div>
						<div class="checkbox-container">
							<div class={state.error ? "colorerror" : "hide"}>{state.error}</div>
						</div>
						<div class="button-area">
							<button class="btn btn-primary" onclick={() => actions.savePassword()}>SAVE</button>
						</div>
					</div>

					<div class={state.success ? "full-width" : "hide"} >
						<h1 class="full-width thin">{state.success}</h1>
						<br></br>
						<Link to="/login" class="colorlightgrey">Back to log In</Link>
					</div>

				</div>
			</div>
		</div>
	</div>
)

const state = {
	token: null,
	email: '',
	password: '',
	error: null,
	success: null,
}

let nakermodel;
let nakerlight;
let nakertext;
let points;

const actions = {
	updateEmail: value => state => ({ email: value, error: null }),
	updatePassword: value => state => ({ password: value, error: null }),

	showError: value => state => ({ error: value }),
	showSuccess: value => state => ({ success: value }),

	setToken: value => state => ({ token: value, error: null }),

	testToken: () => (state, actions) => {
		let contents = nakerscene.create('loginnakerscene');
		nakermodel = contents.model;
		nakertext = contents.text

		contents.model.setScale({ x: 4, y: 4, z: 4 });
		contents.light.setIntensity(50);
		contents.model.pauseAnimation();
		contents.text.pauseAnimation();

		var url = window.location;
		var tokenArr = /token=([^&]+)/.exec(url);
		if (tokenArr != null) {
			let token = tokenArr[1];
			actions.setToken(token);
		} else {
			actions.setToken(null);
		}
	},

	keypressEmail: (e) => (state, actions) => {
		if (e.which == 13) actions.sendEmail();
	},

	sendEmail: () => (state, actions) => {
		if (state.email.indexOf('@') == -1) return actions.showError('Wrong E-mail');
		api.post('user/password', { email: state.email }, (data) => {
			if (data.success) {
				actions.showSuccess('Thanks! Now just check your email inbox.')
				nakermodel.playAnimation();
				nakertext.playAnimation();
			} else {
				actions.showError(data.message);
			}
		});
	},

	keypressPassword: (e) => (state, actions) => {
		if (e.which == 13) actions.savePassword();
	},

	savePassword: () => (state, actions) => {
		if (state.password.length < 6) return actions.showError('Password must have 6 characters');
		api.post('user/confirmpassword', { password: state.password, token: state.token }, (data) => {
			if (data.success) {
				actions.showSuccess('Good! Your Password has been reset');
				nakermodel.playAnimation();
				nakertext.playAnimation();
			} else {
				actions.showError(data.message);
			}
		});
	},
}

const Password = {};
Password.view = view;
Password.actions = actions;
Password.state = state;

module.exports = Password;
