
import axios from 'axios';
import Cookies from 'js-cookie';

import * as Sentry from "@sentry/browser";

const env = require('./environment');

if (env.sentry)
	Sentry.init({
		dsn: "https://eaa72555b7c14c569c14bff62398abe6@sentry.io/1522587"
	});
  

const spyservice = require('../services/spyservice');

const api = {
	host: '',
	access_token: '',
	refresh_token: '',
	currentcheck: false,

	getQueryParam(param) {
		param = param.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
		var regexS = "[\\?&]" + param + "=([^&#]*)",
			regex = new RegExp(regexS),
			results = regex.exec(document.URL);
		if (results === null || (results && typeof (results[1]) !== 'string' && results[1].length)) {
			return '';
		} else {
			return decodeURIComponent(results[1]).replace(/\+/g, ' ');
		}
	},

	getToken() {
		let tokenCookie = Cookies.get('token');
		let tokenObject = JSON.parse(tokenCookie);
		this.setToken(tokenObject);
	},

	ObjecToQuery(obj) {
		var str = '';
		for (var key in obj) {
			if (obj.hasOwnProperty(key)) {
				let param = encodeURIComponent(obj[key]);
				str += key + '=' + param + '&';
			}
		}
		return str;
	},

	setToken(token) {
		this.access_token = token.access_token;
		this.refresh_token = token.refresh_token;
		axios.defaults.headers.common['Authorization'] = token.access_token;
	},

	saveToken(token) {
		var cookie = { access_token: token.access_token, refresh_token: token.refresh_token };
		Cookies.set('token', JSON.stringify(cookie), { expires: 7 });
	},

	get(path, arg, callback) {
		let query = arg ? this.ObjecToQuery(arg) : '';
		let url = this.host + path + '?' + query;
		this.send('GET', url, '', {}, (data) => {
			callback(data);
		});
	},

	post(path, arg, callback) {
		let query = arg ? this.ObjecToQuery(arg) : '';
		let url = this.host + path + '?' + query;
		this.send('POST', url, '', {}, (data) => {
			callback(data);
		});
	},

	put(path, arg, callback) {
		let query = arg ? this.ObjecToQuery(arg) : '';
		let url = this.host + path + '?' + query;
		this.send('PUT', url, '', {}, (data) => {
			callback(data);
		})
	},

	postBody(path, arg, options, callback) {
		let bodystring = options.body ? options.body : '';
		let header = options.header ? options.header : {};
		let query = arg ? this.ObjecToQuery(arg) : '';
		let url = this.host + path + '?' + query;
		this.send('POST', url, bodystring, header, (data) => {
			callback(data);
		});
	},

	send(method, url, body, headers, callback) {
		headers.Authorization = this.access_token;
		if (method == 'POST') {
			axios.post(url, body, { headers: headers })
				.then((response) => { callback(response.data) })
				.catch((error) => {
					console.log(error);
					this.checkError(error, method, url, body, headers, callback)
				});
		}
		else if (method == 'PUT') {
			axios.put(url, body, { headers: headers })
				.then((response) => { callback(response.data) })
				.catch((error) => {
					console.log(error);
					this.checkError(error, method, url, body, headers, callback)
				});
		} else if (method == 'GET') {
			axios.get(url, { headers: headers })
				.then((response) => { callback(response.data) })
				.catch((error) => {
					console.log(error);
					this.checkError(error, method, url, body, headers, callback)
				}
				);
		} else {
			return console.log('Method must be GET or POST');
		}
	},

	// If catch error after request, there is no response in error.
	checkError(error, method, url, body, headers, callback) {
		if (error) {
			if (error.response) {
				this.checkToken(url, error.response.data, (result) => {
					if (result) {
						this.send(method, url, body, headers, callback);
					} else {
						callback(error.response.data);
					}
				});
			} else {
				callback({ success: false });
			}
		} else {
			callback({ success: false });
		}
	},

	checkToken(url, error, callback) {
		if (error.message == 'Bad Token.' && url.indexOf('refreshtoken') == -1) {
			let query = this.ObjecToQuery({ refresh_token: this.refresh_token })
			this.send('POST', this.host + 'user/refreshtoken?' + query, '', {}, (data) => {
				if (data.success) {
					this.setToken(data);
					this.saveToken(data);
					callback(true);
				} else {
					// If can't refresh token go back to login
					Cookies.remove('token');
					// settimeout to be sure cookies are removed
					setTimeout(() => {
						window.location = '/login';
					}, 100);
				}
			})
		} else {
			callback(false);
		}
	},

	disconnect(state, actions) {
		Cookies.remove('token');
		spyservice.reset();
		setTimeout(() => {
			window.location = '/login';
		}, 100)
	},
}

api.host = env.apiurl;

module.exports = api;
