import { h } from "hyperapp";
import { Link, Route } from "@hyperapp/router";

const Data = require('./data');
const Users = require('./users');
const Projects = require('./projects');

import '../../styles/table.sass';

const view = (state, actions) => props => {
	let match = props.match;
  if (match == undefined) return;
  return (
    <div>
      <div class="top-bar">
        <div class="search-container deepsea-navbar">
					<Link class="btn btn-primary navbar-element" to={`/data/main`}><div class="navbar-text">Main</div></Link>
          <Link class="btn btn-primary navbar-element" to={`/data/users`}><div class="navbar-text">Users</div></Link>
          <Link class="btn btn-primary navbar-element" to={`/data/projects`}><div class="navbar-text">Projects</div></Link>
        </div>
      </div>
      <Route parent path={`${match.path}/main`} render={Data.view(state.data, actions.data)} />
			<Route parent path={`${match.path}/users`} render={Users.view(state.users, actions.users)} />
			<Route parent path={`${match.path}/projects`} render={Projects.view(state.projects, actions.projects)} />
    </div>
  )
}

const state = {
	data: Data.state,
	users: Users.state,
	projects: Projects.state
}

const actions = {
	data: Data.actions,
	users: Users.actions,
	projects: Projects.actions
}

const MainData = {};
MainData.view = view;
MainData.state = state;
MainData.actions = actions;

module.exports = MainData;
