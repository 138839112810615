
import { h } from "hyperapp";
import { Link } from "@hyperapp/router";

const api = require('../services/api');
const spyservice = require('../services/spyservice');

const view = (state, actions) => props => (
	<div oncreate={element => { actions.testToken(state, actions); element.focus(); }} class="login-back">
		<div class="login-wrapper">
			<div class={(!state.success && !state.error) ? "login-form" : "hide"} >
				<div class="center">
					<div class="dot-1"></div>
					<div class="dot-2"></div>
					<div class="dot-3"></div>
				</div>
			</div>
			<div class={state.success ? "login-form transparent-container" : "hide"}>
				<h1 class="full-width thin">Your account is now activated</h1>
			</div>
			<div class={state.error ? "login-form transparent-container" : "hide"}>
				<h1 class="thin">Your token is not valid</h1>
			</div>
		</div>
	</div>
)


const state = {
	error: false,
	success: false,
}

const actions = {
	showError: value => state => ({ error: true }),
	showSuccess: value => state => ({ success: true }),

  testToken: () => (state, actions) => {
    setTimeout(() => {
      window.location.href = 'login';
    }, 4000);
    var url = window.location;
    var tokenArr = /token=([^&]+)/.exec(url);
    if (tokenArr != null) {
      let token = tokenArr[1];
      api.post( 'user/confirm', {token:token}, (data) => {
        if (data.success) {
          actions.showSuccess();
          spyservice.track("Email Confirm");
          setTimeout(() => {
            window.location.href = 'login';
          }, 5000);
        } else {
          spyservice.track("Email Confirm Failed");
          actions.showError();
          setTimeout(() => {
            window.location.href = 'signup';
          }, 5000);
        }
      });
    } else {
      actions.showError();
    }
  },
}

const Confirm = {};
Confirm.view = view;
Confirm.actions = actions;
Confirm.state = state;

module.exports = Confirm;
