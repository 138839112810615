
import { h, app } from "hyperapp";
import { Route, location } from "@hyperapp/router";

import '../styles/custom-siimple.sass';
import '../styles/main.sass';
import 'microtip/microtip.css';
import '../styles/taggle.sass';
import '../styles/card.sass';
import '../styles/modal.sass';
import '../styles/font.css';
import '../styles/toastr.sass';
import '../styles/login.sass';
import '../styles/dashboard.sass';

const Login = require('./auth/login');
const Confirm = require('./auth/confirm');
const Password = require('./auth/password');
const Dashboard = require('./dashboard/dashboard');
const Deepsea = require('./deepsea/main');
const env = require('./services/environment');

const state = {
	location: location.state,
	login: Login.state,
	confirm: Confirm.state,
	password: Password.state,
	dashboard: Dashboard.state,
	data: Deepsea.state,
}

const actions = {
	location: location.actions,
	login: Login.actions,
	confirm: Confirm.actions,
	password: Password.actions,
	dashboard: Dashboard.actions,
	data: Deepsea.actions,
}

let view;
if (env.mode === 'deepsea') {
	view = (state, actions) => (
		<div class="root">
			<Route path="/" render={Login.view(state.login, actions.login)} />
			<Route path="/login" render={Login.view(state.login, actions.login)} />
			<Route path="/signup" render={Login.view(state.login, actions.login)} />
			<Route parent path="/data" render={Deepsea.view(state.data, actions.data)} />
		</div>
	)
} else if (env.mode !== 'development') {
	view = (state, actions) => (
		<div class="root">
			<Route path="/" render={Login.view(state.login, actions.login)} />
			<Route path="/login" render={Login.view(state.login, actions.login)} />
			<Route path="/signup" render={Login.view(state.login, actions.login)} />
			<Route path="/confirm" render={Confirm.view(state.confirm, actions.confirm)} />
			<Route path="/password" render={Password.view(state.password, actions.password)} />
			<Route parent path="/dashboard" render={Dashboard.view(state.dashboard, actions.dashboard)} />
		</div>
	)
} else {
	view = (state, actions) => (
		<div class="root">
			<Route path="/" render={Login.view(state.login, actions.login)} />
			<Route path="/login" render={Login.view(state.login, actions.login)} />
			<Route path="/signup" render={Login.view(state.login, actions.login)} />
			<Route path="/confirm" render={Confirm.view(state.confirm, actions.confirm)} />
			<Route path="/password" render={Password.view(state.password, actions.password)} />
			<Route parent path="/data" render={Deepsea.view(state.data, actions.data)} />
			<Route parent path="/dashboard" render={Dashboard.view(state.dashboard, actions.dashboard)} />
		</div>
	)
}

const main = app(state, actions, view, document.body)

const unsubscribe = location.subscribe(main.location)
