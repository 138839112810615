
import { h } from "hyperapp";
import { Link } from "@hyperapp/router";

const api = require('../services/api');
const env = require('../services/environment');

const view = (state, actions) => props => {
	return (
		<div class="deepsea-mainpage" oncreate={element => actions.getData()}>
			<div class="projects-container" style="margin-top: 75px; margin-left:50px; margin-bottom: 50px">
				<div class="templates-input form-group">
					<input
						type='text'
						placeholder=""
						oninput={e => actions.searchUser(e.target.value)}
					/>
					<span class="label">Search a user!</span>
					<span class="border"></span>
				</div>
				<div class="old_ie_wrapper">
					{state.searchedUsers.length == 0 ? null :
						<table class="fixed_headers">
							<thead>
								<tr>
									<th class="table-name">Name</th>
									<th class="table-name">Email</th>
									<th class="table-id">ID</th>
									<th class="table-link">Link to profile</th>
								</tr>
							</thead>
							<tbody>
								{state.searchedUsers.map(user => (
									<tr>
										<td class="table-name">{user.name}</td>
										<td class="table-name">{user.email}</td>
										<td class="table-id">{user._id}</td>
										<td class="table-link">
											<Link class={"linktoproject projectpublished"} to={`/data/users/` + user._id} onclick={() => {actions.getData(user._id)}}>
												<div>{user.name}</div>
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					}
				</div>
			{!state.user.name ? null :
				<table class="fixed_headers">
					<thead>
						<tr>
							<th class="table-name">Name</th>
							<th class="table-id">ID</th>
							<th class="table-created">Created</th>
							<th class="table-deleted">Deleted</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="table-name">{state.user.name}</td>
							<td class="table-id">{state.user._id}</td>
							<td class="table-created">{state.user.projectcreated}</td>
							<td class="table-deleted">{state.user.projectdeleted}</td>
						</tr>
					</tbody>
				</table>
			}
			</div>
			{!state.user.name ? null :
			<div class="old_ie_wrapper">
				<table class="fixed_headers">
					<thead>
						<tr>
							<th class="table-name">Name</th>
							<th class="table-id">ID</th>
							<th class="table-model">Model Size</th>
							<th class="table-view">Views</th>
							<th class="table-link">Link to project</th>
							<th class="table-link">Link to logs</th>
						</tr>
					</thead>
					<tbody>
						{state.projects.map(project => (
							!project.published ? null : 
							<tr>
								<td class="table-name">{project.name}</td>
								<td class="table-id">{project._id}</td>
								<td class="table-model">{project.model_size}</td>
								<td class="table-view">{project.views}</td>
								<td class="table-link">
									<a href={'https://harbor.naker.io/' + project._id} class="linktoproject projectpublished" target='_blank'>Harbor</a>
								</td>
								<td class="table-link">
									<Link class={"linktoproject projectpublished"} to={`/data/projects/` + project._id}>
										<div>Logs</div>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			}
		</div>
	)
}

const state = {
	views: 0,
	user: [],
	projects: [],
	logs: [],
	searchedUsers: [],
	searching: false
}

let searchTimeout;

const actions = {

	searchUser: (value) => (state, actions) => {
		if (searchTimeout) clearTimeout(searchTimeout);
		actions.setSearching(true);
		searchTimeout = setTimeout(() => {
			// Search on project names and not templates
			api.get('user/search/', { name: value }, (data) => {
				actions.setSearching(false);
				if (data.success != undefined) {
				} else {
					actions.setSearchedUsers(data);
				}
			});
		}, 1000);
	},

	getData: (id = null) => (state, actions) => {
		if (id == null)
			id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
		api.getToken();
		if (id === 'users' || !id)
			return;
		api.get('deepsea/user', { id: id }, (data) => {
			if (data.success != undefined) {
				api.disconnect();
			} else {
				actions.setData(data);
			}
		});
	},

	getViews: (projects) => (state, actions) => {
		let viewCount = 0;
		projects.map((project) => {
			if (project.published && project.views) {
				viewCount += project.views;
			}
		});
		actions.setViews(viewCount);
	},

	setData: value => (state, actions) => {
		actions.setProjects(value.projects);
		actions.setUser(value.user);
		actions.getViews(value.projects);
	},

	setUser: value => state => ({ user: value }),
	setProjects: value => state => ({ projects: value }),
	setViews: value => state => ({ views: value }),
	setSearching: value => state => ({ searching: value }),
	setSearchedUsers: value => state => ({ searchedUsers: value }),

}

const Data = {};
Data.view = view;
Data.state = state;
Data.actions = actions;

module.exports = Data;
