
const environments = {
	app: {
		mode: 'app',
		apiurl: 'https://naker-backend-prod.herokuapp.com/',
		mixpanelkey: 'fcc1ac86b53acbcb1edc852d7641523b',
		admin: false, // Need to be admin to log and enter
		sentry: true,
	},
	deepsea: {
		mode: 'deepsea',
		apiurl: 'https://naker-backend-prod.herokuapp.com/',
		mixpanelkey: false,
		admin: true,
		sentry: false
	},
	staging: {
		mode: 'staging',
		apiurl: 'https://naker-backend-prod.herokuapp.com/',
		mixpanelkey: '52625b8c5a44789d52d9a01174bb1529',
		admin: true,
		sentry: false
	},
	test: {
		mode: 'staging',
		apiurl: 'https://naker-backend.herokuapp.com/',
		mixpanelkey: false,
		admin: true,
		sentry: false
	},
	development: {
		mode: 'development',
		apiurl: 'http://localhost:3000/',
		mixpanelkey: false,
		admin: false,
		sentry: false
	}
}

function getSubdomain() {
	let hostname = window.location.hostname;
	let regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
	let urlParts = regexParse.exec(hostname);
	return hostname.replace(urlParts[0], '').slice(0, -1);
}
let subdomain = getSubdomain();

export let mode;
if (!subdomain) mode = 'development';
else mode = subdomain;

module.exports = environments[mode];
