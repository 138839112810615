
const env = require('./environment');

const mixpanel = require('mixpanel-browser');

if (env.mixpanelkey) {
  mixpanel.init(env.mixpanelkey);
}

let spyservice = {

  alias : (email) => {
    if (env.mixpanelkey) mixpanel.alias(email);
  },

  identify : (data) => {
    if (env.mixpanelkey) mixpanel.identify(data.email);
    if (env.mode == 'app') {
      window.Intercom('boot', {
        app_id: 'sz0pojg8',
        name: data.name, // Full name
        email: data.email, // Email address
        user_id: data._id, // Email address
        // created_at: “<%= current_user.created_at.to_i %>” // Signup date as a Unix timestamp
      });
    }
  },

  track : (event, options, callback) => {
    if (env.mixpanelkey) mixpanel.track(event, options, callback);
    if (env.mode == 'app') {
      window.Intercom('trackEvent', event, options);
    }
  },

  reset : () => {
    if (env.mixpanelkey) mixpanel.reset();
  }

}

module.exports = spyservice;
