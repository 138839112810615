
import { h } from "hyperapp";
import Cookies from 'js-cookie';
import toastr from 'toastr';
import * as AColorPicker from 'a-color-picker';

const api = require('../services/api');
const env = require('../services/environment');
const nakerscene = require('../services/nakerscene');

const view = (mainstate, mainactions) => props => {
	let state = mainstate.account;
	let actions = mainactions.account;
	let sketchfabUrl = actions.getSketchfabUrl();
	if (mainstate.user) {
		if (mainstate.user.sketchfab && mainstate.user.sketchfab.connected)
			actions.saveSketchfabToken(mainstate.user.sketchfab);
		actions.initAccount(mainstate);
	}
	return (
		<div class="dashboard-page" oncreate={element => actions.showPearl(mainstate)}>
			<div class="account-container transparent-container siimple-grid">
				<div class="siimple-grid-row">
					<div class="siimple-grid-col siimple-grid-col--4 siimple-grid-col--lg-12">
						<div class="form-group">
							<input
								type='text'
								disabled
								placeholder=" "
								value={mainstate.account.name}
								oninput={e => actions.updateName(e.target.value)}
							/>
							<span class="label">Username</span>
							<span class="border"></span>
						</div>
						<div class={mainstate.login ? "hide" : "form-group"}>
							<input
								type='text'
								disabled
								placeholder=" "
								value={mainstate.account.email}
								oninput={e => actions.updateEmail(e.target.value)}
							/>
							<span class="label">E-mail</span>
							<span class="border"></span>
						</div>
						<div class="form-group">
							<input
								type='text'
								placeholder=" "
								value={mainstate.account.company ? mainstate.account.company : ''}
								oninput={e => actions.updateCompany(e.target.value)}
							/>
							<span class="label">Company</span>
							<span class="border"></span>
						</div>

						<div class={!mainstate.account.success ? "form-group" : "hide"}>
							<button class="btn btn-primary" onclick={() => actions.tryUpdate(mainstate)}>Save Changes</button>
						</div>

						<div class="form-group">
							{ mainstate.user.sketchfab && mainstate.user.sketchfab.connected && mainstate.user.sketchfab.refresh_token ?
								<a class="btn btn-primary" style="padding-top:10px; background-color: #64B45C; background-image: unset"
									>Sketchfab account linked!</a>
							:

							<a class="btn btn-primary" style="padding-top:10px"
								href={sketchfabUrl}>Link sketchfab</a>
							}
						</div>
					</div>

					<div class="siimple-grid-col siimple-grid-col--8 siimple-grid-col--lg-hide">
						<div class="account-pearl-container siimple-grid-row">
							<div class="siimple-grid-col siimple-grid-col--6" style="width:50%;">
								<div id="parentscene" class="centerX pearl"></div>
							</div>
							<div class="siimple-grid-col siimple-grid-col--6" style="width:50%;">
								<div class="pearl-button centerX">
									<div id="colorpicker" class="color-picker"></div>
								</div>
							</div>
							<div class="siimple-grid-col siimple-grid-col--12 siimple-grid-col--md-12">
								<div class="centerX">
									<button class="btn btn-primary pearl-btn" onclick={() => actions.updatePearl()}>Update Pearl</button>
									<button class="btn btn-primary pearl-btn" onclick={() => actions.savePearl(mainactions)}>Save Pearl</button>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}

const state = {}

let pearl;
let pearlModel;
let pearlLight;
let pearlPoints;
let pearlColor;
let colorPicker;

const actions = {

	initAccount: value => state => (value.user),

	updateName: value => state => ({ name: value }),
	updateEmail: value => state => ({ email: value }),
	updateCompany: value => state => ({ company: value }),

	tryUpdate: () => (state, actions) => {
		if (state.name.length == 0) return actions.showError('Enter a Username please 🙏');
		if (state.email.indexOf('@') == -1 && state.email.indexOf('.') == -1) return actions.showError('Wrong E-mail');
		if (state.name.length < 6) return actions.showError('Username must have 6 characters at least 🖐️+👍');
		let query = {
			id: state._id,
			name: state.name,
			company: state.company,
			email: state.email
		};
		api.getToken();
		api.post('user/update', query, (data) => {
			if (data.success) {
				toastr.success('Nice, your profile has been updated 🤙');
			} else {
				toastr.error('Can not update your profile for now 😕');
			}
		});
	},

	showPearl: () => (state, actions) => {
		let color = state.pearlcolor;
		pearlColor = (color && color.length != 0) ? color : [255, 255, 255];
		pearl = nakerpearl.render({ container: document.getElementById('parentscene'), model: 'pearl', modelFollowMouseRapidity: 2 }, (pearl) => {
			if (state.pearl != undefined) {
				if (state.pearl.length != 0) {
					pearl.model.pattern.mesh._setPositions(state.pearl);
					pearl.model.pattern.setMaterialProperties({ roughness: nakerscene.metallicroughness, metallic: nakerscene.metallicroughness });
				}
			}
			pearl.light.setColor(pearlColor);
			pearl.light.setIntensity(1.5);
			pearl.light.show();
		});

		pearlModel = pearl.model;
		pearlLight = pearl.light;

		colorPicker = AColorPicker.createPicker({
			attachTo: '#colorpicker',
			showHSL: false,
			showRGB: false,
			color: pearlColor,
			showAlpha: false,
			paletteEditable: false,
		});

		colorPicker.onchange = (picker) => {
			pearlLight.setColor(picker.rgba);
			pearlColor = picker.rgba;
		};
	},

	updatePearl: () => (state, actions) => {
		pearlModel.pattern.mesh._update(10);
		pearlModel.show();
	},

	savePearl: (mainactions) => (state, actions) => {
		let pearlMesh = pearlModel.pattern.mesh;
		if (!pearlMesh) toastr.error('Update the pearl first!');
		let newPoints = pearlMesh._getPositions();
		pearlPoints = JSON.stringify(newPoints);
		pearl._system.takeScreenshot({ width: 200, height: 200 }, (image) => {
			var fd = new FormData();
			fd.append("image", image);
			fd.append("points", pearlPoints);
			const header = { "X-Requested-With": "XMLHttpRequest", "Content-Type": "multipart/form-data" };
			api.postBody('user/setpearl', { id: state._id, color: pearlColor }, { body: fd, header: header }, (data) => {
				if (data.success) {
					state.pearl = newPoints;
					state.pearlcolor = pearlColor;
					mainactions.setUser(state);
					toastr.success('Good, your pearl has been updated 😍');
				} else {
					toastr.error('Can not update your pearl for now 😕');
				}
			});
		});
	},

	getSketchfabUrl: () => (state, actions) => {
		let params = {
			response_type: 'code',
			client_id: 'qCaDS6vFWU2jmk5J7mZtOQHBUmhQZgxdV7KuqLUM',
			redirect_uri: env.apiurl + 'sketchfab/oauthCallback',
			state: state._id + ',' + window.location.href,
		};
		let sketchfabUrl = 'https://sketchfab.com/oauth2/authorize/?';
		var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return sketchfabUrl + queryString;
	},

	saveSketchfabToken: (sketchfab) => () => {
		let userCookies = JSON.parse(Cookies.get('token'));
		userCookies.sketchfab_refresh = sketchfab.refresh_token;
		userCookies.sketchfab_access = sketchfab.access_token;
		Cookies.remove('token');
		Cookies.set('token', JSON.stringify(userCookies), { expires: 7 });
	}

}

const Account = {};
Account.view = view;
Account.state = state;
Account.actions = actions;

module.exports = Account;
