
import { h } from "hyperapp";
import toastr from 'toastr';

const ProjectModal = require('./projectmodal');
const api = require('../services/api');
const spyservice = require('../services/spyservice');

const ProjectCard = ({ userid, project, actions }) => (props) => (
	<div class="project" onmouseenter={() => actions.setHovered(project)} onmouseleave={() => actions.setNotHovered(project)}>
		<div class="project-card">
			<img src={project.image ? project.image : 'https://asset.naker.io/image/main/nopicture.jpg'}/>

			<div class={project.hover || project.leave || project.delete ? "project-details project-details-full" : "project-details"}>
				<div class="project-background-button" onclick={() => actions.editProject(project)}></div>
				<div class="project-title" >{project.name}</div>
				<div class='published'>{project.published ? 'Published' : 'Unpublished'}</div>
				<div class="number">{project.published && project.views != undefined ? project.views : '-'}</div>
				<i class="view-icon icon-visible"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>

				<div class={project.hover && !(project.leave || project.delete) ? "hovericon footericon active" : "hovericon footericon"}>
					{!project.worker || (project.worker && !project.worker.working) ?
						<div class="btn btn-primary edit-btn" onclick={() => actions.editProject(project)}>
							Edit intale
						</div>
						:
						<div onclick={() => actions.refreshSocket(project)} class={project.refreshButton ? "btn btn-primary edit-btn refresh-btn-err" : "btn btn-primary edit-btn refresh-btn"}>
							{project.refreshButton ? project.refreshButton : 'Check if someone is working'}
						</div>
					}
					<div class="projecticon btn btn-third" style="margin-left:20px" onclick={() => actions.previewProject(project)}>
						<i class="projecticon-icon icon-preview"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
						<div class="projecticon-title" >Preview</div>
					</div>
					{project.designer == userid ?
						<div class="projecticon btn btn-third" onclick={() => actions.projectModal.showUpdate(project)}>
							<i class="projecticon-icon icon-intalemenu"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
							<div class="projecticon-title" >Options</div>
						</div>
						: null
					}
					{project.designer == userid ?
						<div class="projecticon btn btn-third" onclick={() => actions.duplicateProject({ project, userid })}>
							<i class="projecticon-icon icon-duplicate"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
							<div class="projecticon-title" >Clone</div>
						</div>
						: null
					}
					{(!project.worker || !project.worker.working) && project.designer == userid ?
						<div class="projecticon btn btn-third" onclick={() => actions.setDeleteProject(project)}>
							<i class="projecticon-icon icon-delete"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
							<div class="projecticon-title" >Delete</div>
						</div>
						: null
					}
					{project.designer != userid ?
						<div class="projecticon btn btn-third" onclick={() => actions.setLeaveProject(project)}>
							<i class="projecticon-icon icon-back"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
							<div class="projecticon-title" >Leave</div>
						</div>
						: null
					}
				</div>
			</div>

			<div class={project.leave || project.delete ? "project-delete hovericon footericon active" : "hide"}>
				{project.delete ? 'Are you sure you want to delete the project?' : 'Are you sure you want to leave the project?'}
				<div class="project-delete-buttons">
					{project.delete ?
						<button title="Delete" class="btn btn-primary btn-error" onclick={() => actions.deleteProject({ project, userid })}>Yes</button>
						:
						<button title="Leave" class="btn btn-primary btn-error" onclick={() => actions.leaveTeam(project)}>Yes</button>
					}
					<button title="Not Delete" class="btn btn-primary btn-success" onclick={() => actions.endDeletion()}>No</button>
				</div>
			</div>
			{project.worker && project.worker.working && project.worker.id != userid && !project.hover ?
				<div class="project-worker">
					{project.worker.name} is currently working on this project
				</div>
				: null
			}
		</div>

	</div>
)

const view = (state, actions) => (props) => (
	<div class="dashboard-page scrollbar" oncreate={element => { actions.getProjects(state); }}>
		<ProjectModal.view state={state.project.projectModal} actions={actions.projectModal} />


		<div class="project-left-menu transparent-container">
			<div class="btn btn-third project-left-menu-button" style="margin-top:20px" onclick={() => {document.getElementById('intales').scrollIntoView();}}>
				<i class="project-left-menu-icon icon-image"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>My intales
			</div>
			<div class="btn btn-third project-left-menu-button" onclick={() => {document.getElementById('templates').scrollIntoView();}}>
				<i class="project-left-menu-icon icon-template"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>Explore
			</div>
			<div class="btn btn-third project-left-menu-button" onclick={() => {document.getElementById('tutorials').scrollIntoView();}}>
				<i class="project-left-menu-icon icon-help"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>Tutorials
			</div>
			<div class="btn btn-secondary project-left-menu-button" style="padding:10px;margin-bottom:20px" onclick={() => { api.disconnect(); }}>Logout</div>
		</div>

		<div id="intales" class="projects-container dashboard">
			<h2>My intales<span class="h2span"></span></h2>
			<h3>Create and manage your own projects</h3>
			<div class="projects-list">
				<div class="create project" onclick={() => actions.projectModal.showCreate('scratch')}>
					<div class="backgroundwave create-image"></div>
					<i class="create-icon icon-add"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
					<div class="project-title textcenter" >New empty project</div>
				</div>

				{state.project.projects.map(project => (
					state.user._id != project.designer ? null : (
						<ProjectCard userid={state.user._id} project={project} actions={actions} />
					)
				))}
			</div>

			<h2>My teamtales<span class="h2span"></span></h2>
			<h3>Co-create and manage shared projects</h3>
			<div class="projects-list">
				{state.project.projects.map(project => (
					state.user._id == project.designer ? null : (
						<ProjectCard userid={state.user._id} project={project} actions={actions} />
					)
				))}
			</div>
		</div>

		<div id="templates" class="projects-container templates">
			<h2>Explore<span class="h2span"></span></h2>
			<h3>Need inspiration? Take a look at some awesome projects</h3>
			<div class="projects-list">
				<i class={state.project.searching ? "icon-searching search-input-icon" : "icon-search search-input-icon"}><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
				<div class="templates-input form-group">
					<input
						type='text'
						placeholder=""
						oninput={e => actions.searchTemplates(e.target.value)}
					/>
				</div>

				<div class="templates-list scrollbar">
					{state.project.templates.map(project => (
						<div class="project template-project" onmouseenter={() => actions.setHovered(project)} onmouseleave={() => actions.setNotHovered(project)}>
							<div>
								<img src={project.image ? project.image : 'https://asset.naker.io/image/main/nopicture.jpg'} alt="img11" />
								<div class={project.hover || project.leave || project.delete ? "project-details project-details-full" : "project-details"}>
									<div class="project-title" >{project.name}</div>
									<div class="project-tags" >
										{project.tags.map(tag => (
											<div class="project-tag">{tag}</div>
										))}
									</div>

								</div>
								<div class="template-layer hovericon footericon active">
									<div class="btn btn-primary edit-btn template-btn" onclick={() => actions.projectModal.showCreate(project._id)}>New project</div>
									<br></br>
									<a class="btn btn-third template-second-btn" href={'https://harbor.naker.io/' + project._id} target="_blank">Preview</a>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>

		<div id="tutorials" class="projects-container tutorial">
			<h2>Tutorials<span class="h2span"></span></h2>
			<h3>A little lost? Go for a cruise and learn more about how Naker works!</h3>
			<a href="https://cruise.naker.io/?product_tour_id=32621" class="tutorial-button" target="_blank">Environment 🏞️</a>
			<a href="https://cruise.naker.io/?product_tour_id=33090" class="tutorial-button" target="_blank">Content 🌈</a>
			<a href="https://cruise.naker.io/?product_tour_id=33157" class="tutorial-button" target="_blank">Animation 🦄️</a>
			<a href="https://cruise.naker.io/?product_tour_id=33227" class="tutorial-button" target="_blank">Journey ️⛵</a>
			<a href="https://cruise.naker.io/?product_tour_id=33264" class="tutorial-button" target="_blank">Hotspot ️📝</a>
			<a href="https://cruise.naker.io/?product_tour_id=33898" class="tutorial-button" target="_blank">Publish ️🙋</a>
		</div>
	</div>
)

const state = {
	confirm: null,
	leave: null,
	searching: false,
	name: '',
	templates: [],
	projects: [],
	searchValue: ""
}

let searchTimeout;

state.projectModal = ProjectModal.state;

const actions = {

	getProjects: (mainstate) => (state, actions) => {
		actions.setProjects(mainstate.user.projects);
		api.get('project/templates', {}, (data) => {
			if (data.success != undefined) {
			} else {
				actions.setTemplates(data);
			}
		});
		actions.projectModal.setUser(mainstate.user);
	},

	setHovered: (project) => (state, actions) => {
		let projects = state.projects;
		for (var i = 0; i < projects.length; i++) {
			if (projects[i]._id == project._id) projects[i].hover = true;
			else projects[i].hover = false;
		}
		actions.setProjects(projects);
	},

	setNotHovered: () => (state, actions) => {
		let projects = state.projects;
		for (var i = 0; i < projects.length; i++) {
			projects[i].hover = false;
		}
		actions.setProjects(projects);
	},

	setDeleteProject: (project) => (state, actions) => {
		let projects = state.projects;
		for (var i = 0; i < projects.length; i++) {
			if (projects[i]._id == project._id) projects[i].delete = true;
			else projects[i].delete = false;
		}
		actions.setProjects(projects);
	},

	setLeaveProject: (project) => (state, actions) => {
		let projects = state.projects;
		for (var i = 0; i < projects.length; i++) {
			if (projects[i]._id == project._id) projects[i].leave = true;
			else projects[i].leave = false;
		}
		actions.setProjects(projects);
	},

	endDeletion: (project) => (state, actions) => {
		let projects = state.projects;
		for (var i = 0; i < projects.length; i++) {
			projects[i].delete = false;
			projects[i].leave = false;
		}
		actions.setProjects(projects);
	},

	setProjects: value => state => ({ projects: value }),
	setTemplates: value => state => ({ templates: value }),
	setWorker: value => state => ({ worker: value }),
	setSearching: value => state => ({ searching: value }),
	setRefreshButton: value => state => ({ refreshButton: value }),

	refreshSocket: (project) => (state, actions) => {
		api.post('project/refresh', { id: project._id }, (data) => {
			if (data.success) {
				project.worker = {
					name: '',
					working: false,
					id: '',
					socket: ''
				}
				actions.setWorker(project.worker);
			} else {
				let projects = state.projects;
				for (var i = 0; i < projects.length; i++) {
					if (projects[i]._id == project._id) {
						projects[i].refreshButton = project.worker.name + ' is still working';
						setTimeout(() => {
							let projects = state.projects;
							for (var i = 0; i < projects.length; i++) {
								if (projects[i]._id == project._id) projects[i].refreshButton = null;
							}
						}, 3000);
					}
					actions.setWorker(project.worker);
				}
				actions.setProjects(projects);
			}
		});
	},

	searchTemplates: (value) => (state, actions) => {
		if (searchTimeout) clearTimeout(searchTimeout);
		actions.setSearching(true);
		searchTimeout = setTimeout(() => {
			api.get('project/templates', { query: value }, (data) => {
				actions.setSearching(false);
				if (data.success != undefined) {
				} else {
					actions.setTemplates(data);
				}
			});
		}, 1000);
	},

	duplicateProject: ({ project, userid }) => (state, actions) => {
		api.post('project/duplicate', { id: project._id }, (data) => {
			if (data.success != undefined) {
				toastr.error('Oups we had an issue while duplicating the project. 🙁');
			} else {
				spyservice.track("Story Create", {
				    from: 'duplicate'
				});
				toastr.success('Good, your project has been duplicated. 👍');
				state.projects.push(data);
				actions.setProjects(state.projects);
			}
		});
	},

	editProject: (project) => (state, actions) => {
		spyservice.track("Story Edit");
		setTimeout(() => {
			window.location.href = '/story/' + project._id;
		}, 100);
	},

	previewProject: (project) => (state, actions) => {
		spyservice.track("Story Preview");
		setTimeout(() => {
			window.location.href = 'https://harbor.naker.io/' + project._id;
		}, 100);
	},

	leaveTeam: (project) => (state, actions) => {
		let id = project._id;
		api.post('project/leave', { id }, (data) => {
			if (!data.success) {
				toastr.error('Arf there was an issue while trying to leave this project. 👿')
			} else {
				let projects = state.projects;
				for (var i = 0; i < projects.length; i++) {
					if (projects[i]._id == id) {
						state.projects.splice(i, 1);
					}
				}
				actions.setProjects(projects);
				toastr.success('Project left! 👌');
			}
		});
	},

	deleteProject: ({ project, userid }) => (state, actions) => {
		api.post('project/delete', { id: project._id }, (data) => {
			if (!data.success) {
				toastr.error('Arf there was an issue while trying to delete your project. 👿')
			} else {
				spyservice.track("Story Delete");
				toastr.success('Project deleted! 👌');
				let projects = state.projects;
				for (var i = 0; i < projects.length; i++) {
					if (projects[i]._id == project._id) {
						state.projects.splice(i, 1);
					}
				}
				actions.setProjects(state.projects);
				actions.endDeletion();
			}
		});
	},

}

actions.projectModal = ProjectModal.actions;

const Project = {};
Project.view = view;
Project.state = state;
Project.actions = actions;

module.exports = Project;
