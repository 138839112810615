
import { h } from "hyperapp";
import Taggle from "taggle"
import toastr from 'toastr'
import MicroModal from 'micromodal';
import { pull, uniq } from 'lodash';
import Suggestions from 'suggestions';

import '../../styles/suggestions.sass';

const api = require('../services/api');
const spyservice = require('../services/spyservice');

const view = ({ state, actions }) => (props) => (
  <div class="modal micromodal-slide" id="newproject-modal" aria-hidden="true" oncreate={element => {actions.initTagInputs(state);}}>
    <div class="siimple-grid">
      <div class="modal__overlay" tabindex="-1" data-micromodal-close>
        <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
          <header class="modal__header">
            <h2 class="modal__title" id="modal-1-title">
              {state.title}
              <span class="h2span"></span>
            </h2>
            <button class="modal__close icon-cancel" aria-label="Close modal" data-micromodal-close></button>
          </header>
          <main class="modal__content" id="modal-1-content">
            <div class="siimple-grid-row">
              <div class="my-columns">
                <div class="form-group">
                  <input
                    type='text'
                    placeholder=" "
                    value={state.name}
                    oninput={e => actions.updateName(e.target.value)}
                  />
                  <span class="label">Project Name</span>
                </div>

                <div class="form-group">
                  <span class="tags-label modal-label" for="Tags">Tags</span>
                  <div id="project-tags" class="tags scrollbar"></div>
                </div>
              </div>
              <div class="my-columns">
                <div class="">
                  <span class="modal-label">Collaborators</span>
                  <div class="member-map">
                    { state.currentTeam.map(member => (
											<div class="member-names">{ member.name }
                        {state.worker && state.worker.name == member.name ? <div class="worker-class">Working</div>
                        : <div style="float: right;" class="siimple-close colorerrorbg" onclick={() => actions.removeFromTeam(member)}></div>}
                      </div>
                    ))}
                  </div>
                </div>

                <div style="position:relative">
                  <div class="team-input">
                    <span class="modal-label">Add Collaborators</span>
                    <input
                      id="searchCollaboratorInput"
                      type="text"
                      placeholder=""
                      value={state.searchValue}
                      readonly={state.readOnlyInput}
                      oninput={e => actions.searchMembers(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class={state.user.admin ? "form-group" : "hide"}>
              <div class={state.template ? "template colorlightmain colordarkgreybg" : "template colorlightmain colorsecondgreybg"} onclick={()=>actions.setTemplate(state)}>{!state.template ? "Ceci n'est pas une template" : "Ceci est une template"}</div>
            </div>

            <br></br>
            <div style="text-align:center;">
              <button class="btn btn-project btn-primary" onclick={()=>actions.projectClick(state)}>{state.button}</button>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
)

const state = {
  name: '',
  id: '',
  tags: [],
  user: {},
  title: 'New Project',
  button: 'Create',
  requestsent: false,
  currentMode: 'create',
  template: false,
  searching: false,
  selectedtemplate: null,
  teamSearchList: [],
  teamIds: [],              // This is the array of IDs
  currentTeam: [],       // This is the array of Names + Emails + IDs
  searchValue: "",
	readOnlyInput: false,
	worker: {}
}

let taggleTags;
let searchTimeout;
let suggestionList;

const actions = {
  initTagInputs: (mainstate) => (state, actions) => {
    let tagscontainer = document.querySelector('#project-tags');
    if (tagscontainer == null ) return;
    if (tagscontainer.childNodes.length != 0 ) return;
    taggleTags = new Taggle('project-tags', {
      duplicateTagClass: 'bounce',
      saveOnBlur:true,
      placeholder:'comma separated words',
      onBeforeTagAdd: (event, tag) => {
        if (tag.indexOf('#') != -1) {
          toastr.error('No need to put #️ in your tag');
          return false;
        } else {
          return true;
        }
      },
      onTagAdd: (event, tag) => {
        actions.setTags(taggleTags.tag.values);
      },
      onTagRemove: (event, tag) => {
        actions.setTags(taggleTags.tag.values);
      }
    });
    let suggestionInput = document.querySelector('#searchCollaboratorInput');
    suggestionList = new Suggestions(suggestionInput, [], {
      minLength: 1,
			limit: 5,
			filter: false
    });

    suggestionList.getItemValue = function(item) {
      return item.name;
    };

    suggestionInput.addEventListener('change', function() {
      if (suggestionList.selected && suggestionList.selected != undefined) {
				actions.addToTeam(suggestionList.selected);
				suggestionList.selected = [];
      }
    });

    MicroModal.init();
  },

  setDropdownList: (searchList) => () => {
    suggestionList.update(searchList);
  },

  setUser: value => state => ({ user: value }),
  setSearchList: value => state => ({ teamSearchList: value }),
  setCurrentTeam: value => state => ({ currentTeam: value }),
  setTeamIds: value => state => ({ teamIds: value }),
  setWorker: value => state => ({worker: value}),

  setSelectedTemplate: value => state => ({ selectedtemplate: value }),

  setSearching: value => state => ({ searching: value }),
  setSearchValue: value => state => ({ searchValue: value }),
  setReadOnlyInput: value => state => ({ readOnlyInput: value }),

  updateName: value => state => ({ name: value }),
  emptyForm: value => state => ({ error: null, success:null, name:'', tags: [], requestsent: false, teamSearchList: [], currentTeam: [], id: '', teamIds: [], name: '', worker: {}}),

  setTags: value => state => ({ tags: value}),
  setCreateMode: value => state => ({ currentMode: 'create', title: 'New Project', button: 'Create', name: ''}),
  setCreating: value => state => ({ button: 'Creating New Project...', requestsent: true}),
  setUpdateMode: value => state => ({ currentMode: 'update', title: 'Update Project', button: 'Update', id: value._id, name: value.name, template:value.template }),
  setUpdating: value => state => ({ button: 'Updating Project...', requestsent: true}),
  unsetRequestSent: value => state => ({ requestsent: false}),

  projectClick: (mainstate) => (state, actions) => {
    if (state.currentMode == 'create') actions.tryCreate(mainstate);
    else actions.tryUpdate(mainstate);
  },

  showCreate: (value) => (state, actions) => {
    actions.setSearchValue('');
    taggleTags.removeAll();
    actions.emptyForm();
    actions.setCreateMode();
    actions.setSelectedTemplate(value);
    MicroModal.show('newproject-modal');
    suggestionList.update([]);
  },

  tryCreate: (mainstate) => (state, actions) => {
    if (state.requestsent) return;
    setTimeout(() => {
      actions.unsetRequestSent();
    }, 3000);
    actions.setCreating();
    if (state.name.length == 0) state.name = 'Project '+state.user.projects.length;
    let query = {
      id:state.user._id,
      createfrom:state.selectedtemplate,
      name:state.name,
      tags:state.tags.join(','),
      template:state.template,
      team: state.teamIds.join(',')
    };
    api.post( 'project/new', query, (data) => {
      if (data.success != undefined) {
        toastr.error('Oups we had an issue while creating your project. 🙁');
      } else {
        let from = (state.selectedtemplate == 'scratch')? 'scratch' : 'template';
        spyservice.track("Story Create", {from:from});
        setTimeout(() => {
          window.location.href = '/story/'+data._id;
        }, 100);
      }
    });
  },

  setTemplate: project => state => ({ template: !state.template }),

  showUpdate: (project) => (state, actions) => {
    actions.setSearchValue('');
    actions.setReadOnlyInput(false);
    taggleTags.removeAll();
    actions.emptyForm();
    actions.setUpdateMode(project);
    taggleTags.add(project.tags);
    actions.getCurrentTeam(project);
    if (project.team.length > 4) {
      actions.setSearchValue('Your team is full!');
      actions.setReadOnlyInput(true);
		}
		actions.setWorker(project.worker);
    MicroModal.show('newproject-modal');
  },

  tryUpdate: (mainstate) => (state, actions) => {
    // NOTE temporary fix after renaming public by template
    if (state.requestsent) return;
    setTimeout(() => {
      actions.unsetRequestSent();
    }, 3000);
    actions.setUpdating();
    if (state.template == undefined) state.template = false;
    let body = { name:state.name, tags:state.tags.join(','), template:state.template, team: state.teamIds.join(','), id: state.id};
    api.put( 'project/update', body, (data) => {
      if (!data.success) {
        spyservice.track("Story Update");
        toastr.error('oh no we had an error while updating the project. 👿')
      } else {
        MicroModal.close('newproject-modal');
        toastr.success('Good, your project has been updated. 😃');
        let projects = state.user.projects;
        for (var i = 0; i < projects.length; i++) {
          if(projects[i]._id == state.id) {
            projects[i].name = state.name;
            projects[i].template = state.template;
            projects[i].tags = state.tags.slice(0); // Slice to clone
            projects[i].team = state.teamIds;
          }
        }
        actions.setCreateMode();
        taggleTags.removeAll();
        actions.emptyForm();
      }
    });
  },

  addToTeam: (value) => (state, actions) => {
		actions.setSearchValue('');
    state.teamIds.push(value._id);
    if (state.teamIds.length > 4) {
      actions.setSearchValue('Your team is full!');
      actions.setReadOnlyInput(true);
		}
		actions.getTeam(state.teamIds);
    actions.setSearchList([]);
  },

  removeFromTeam: (member) => (state, actions) => {
		let userId = member._id;
		pull(state.teamIds, userId);
		actions.setSearchValue('');
		actions.setReadOnlyInput(false);
		actions.getTeam(state.teamIds);
  },

  getTeam: (team) => (state, actions) => {
		team = uniq(team);
		team = team.filter(function (el) { return el != undefined; });
    if (team.length > 0) {
      api.get('project/team', {id: team}, (data) => {
        if (data.success != undefined) {
        } else {
          actions.setCurrentTeam(data);
          actions.setTeamIds(team);
        }
      })
    } else  {
      actions.setCurrentTeam([]);
      actions.setTeamIds([]);
    }
  },

  getCurrentTeam: (project) => (state, actions) => {
		api.get('project/teamlist', {id: project._id}, (data) => {
      if (data.success) {
        actions.setCurrentTeam(data.userList);
        actions.setTeamIds(data.teamIds);
      }
    });
  },

  searchMembers: (value) => (state, actions) => {
    actions.setSearchValue(value);
    if (searchTimeout) clearTimeout(searchTimeout);
    actions.setSearching(true);
    if (value) {
      searchTimeout = setTimeout(() => {
        api.get('user/search', {name: value}, (data) => {
          actions.setSearching(false);
          if (data.success != undefined) {
          } else {
            actions.setSearchList(data);
            actions.setDropdownList(data);
          }
        })
      }, 1000);
    } else {
      actions.setSearchList([]);
      actions.setDropdownList([]);
    }
  },
}

const ProjectModal = {};
ProjectModal.view = view;
ProjectModal.state = state;
ProjectModal.actions = actions;

module.exports = ProjectModal;
