
import { h } from "hyperapp";
import { Link } from "@hyperapp/router"
import Cookies from 'js-cookie'

const api = require('../services/api');
const nakerscene = require('../services/nakerscene');
const spyservice = require('../services/spyservice');
const env = require('../services/environment');

import StoryImg from '../../styles/image/StoryWhite.png';
import BackImg from '../../styles/image/BackWhite.png';
import FormImg from '../../styles/image/FormWhite.png';


const view = (state, actions) => props => (
	<div oncreate={element => { actions.checkCookie(props); element.focus(); }} class={state.checkedcookie ? "login-back" : "hide"}>
		<img src="https://asset.naker.io/image/main/Logo_Naker_Mini.png" class="main-logo"></img>
		<div class="siimple-grid">
			<div class="siimple-grid-row">
				<div class="login-wrapper siimple-grid-col siimple-grid-col--4 siimple-grid-col--xl-6 siimple-grid-col--md-12">
					<div class="login-form transparent-container">
						<h1>{state.login ? "Log In" : "Sign Up"}</h1>
						<h4>{state.login ? "Good to see you again" : "Join our community of 3D crafters"}</h4>
						{/* <h5>{state.login ? "" : "Signing up will give you access to Naker .Story .Back .Form"}</h5> */}
						<div class="form-group">
							<input
								type='text'
								placeholder=" "
								value={state.name}
								oninput={e => actions.updateName(e.target.value)}
								onkeypress={(e) => actions.keypressInput(e)}
							/>
							<span class="label">{state.login ? "Username or E-mail" : "Username"}</span>
						</div>
						<div class={state.login ? "hide" : "form-group"}>
							<input
								type='text'
								placeholder=" "
								spellcheck="false"
								value={state.email}
								oninput={e => actions.updateEmail(e.target.value)}
								onkeypress={(e) => actions.keypressInput(e)}
							/>
							<span class="label">E-mail</span>
						</div>
						<div class="form-group">
							<input
								type='password'
								placeholder=" "
								spellcheck="false"
								value={state.password}
								oninput={e => actions.updatePassword(e.target.value)}
								onkeypress={(e) => actions.keypressInput(e)}
							/>
							<span class="label">Password</span>
						</div>
						<div class={state.login ? "hide" : "form-group"}>
							<div class="select">
								<select class="select-text" required oninput={e => actions.updateType(e.target.value)}>
									<option value="" disabled selected></option>
									<option value="student">Student</option>
									<option value="freelance">Freelance</option>
									<option value="agency">Digital Agency</option>
									<option value="brand">Brand/Company/Advertiser</option>
								</select>
								<span class="select-highlight"></span>
								<span class="select-bar"></span>
								<label class="select-label">You describe yourself as</label>
							</div>
						</div>

						<div class="checkbox-container">
							<Link to="/password" class={state.login ? "" : "hide"} >Forgot Password?</Link>
						</div>
						<div class="checkbox-container">
							<div class={state.error ? "colorerror" : "colorerror hide"} >{state.error}</div>
						</div>

						<div class="button-area">
							<button class="btn btn-primary width100" onclick={() => actions.clickConnect()}>{state.login ? "Log In" : "Sign up"}</button>
							<Link to="/signup" class={state.login ? "btn btn-secondary width100" : "hide"}>Create Account</Link>
							<Link to="/login" class={!state.login ? "btn btn-secondary width100" : "hide"}>Already an account?</Link>
						</div>

						<div class={state.loading ? "frame" : "hide"}>
							<div class="center">
								<div class="dot-1"></div>
								<div class="dot-2"></div>
								<div class="dot-3"></div>
							</div>
							<br></br><br></br><br></br><br></br>
							<div>Loading...</div>
						</div>

						<div class={state.success ? "frame" : "hide"}>
							<div>
								{state.success}
							</div>
							<br></br>
							<div class={state.pearl ? "" : "hide"}>
								<button class="btn btn-primary width120" onclick={() => actions.updatePearl()}>Update Pearl</button>
								<button class="btn btn-primary width120" style="margin-left:10px" onclick={() => actions.savePearl()}>Save my Pearl</button>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
)

const state = {
	login: true,
	id: '',
	name: '',
	email: '',
	password: '',
	type: '',
	error: null,
	loading: false,
	success: false,
	pearl: false,
	checkedcookie: false,
	animInterval: null,
}

let nakermodel;
let nakerlight;
let nakertext;
let nakerheightmap;
let defaultLightIntensity = 1000;
let defaultFogDensity = 0.9;

const actions = {

	checkCookie: (props) => (state, actions) => {
		let tokenCookie = Cookies.get('token');
		if (tokenCookie === undefined)
			actions.checkOver(props);
		else {
			api.getToken();
			api.get('user', {}, (data) => {
				if (data.success) {
                    actions.checkOver(props);
				} else {
					actions.redirect();
				}
			});
		}

        if (nakerscene.loaded) actions.checkPage(props);
	},

	redirect: () => (state, actions) => {
		
		if (env.mode == 'deepsea') {
			window.location.href = 'data/main';
		} else {
			let redirect = api.getQueryParam('redirect');
			let last_engine = Cookies.get('last_engine');
			if (last_engine == 'story') window.location.href = 'dashboard/projects';
			else if (last_engine) window.location.href = last_engine;
			else if (redirect) window.location.href = redirect;
			else window.location.href = 'dashboard/projects';
		}
	},

	checkPage: (props) => (state, actions) => {
		actions.resetScene();
		let match = props.match;
		if (state.login && match.url.indexOf('login') == -1) {
			actions.showSignup();
		} else if (!state.login && match.url.indexOf('login') != -1) {
			actions.showLogin();
		}
	},

	resetScene: () => (state, actions) => {
		nakerscene.nakerintale.environment.setFogDensity(defaultFogDensity);
		nakerlight.setIntensity(10);
		nakertext.pauseAnimation();
		nakerheightmap.setScale({ y: 5 });
	},

	updatePearl: () => (state, actions) => {
		nakerscene.updatePearl();
	},

	savePearl: () => (state, actions) => {
		let pearl = nakerscene.getPearl();
		if (!pearl) toastr.error('Update the pearl first!');
		let pearlPoints = JSON.stringify(pearl._getPositions());
		nakerscene.takeScreenshot((image) => {
			var fd = new FormData();
			fd.append("image", image);
			fd.append("points", pearlPoints);
			const header = { "X-Requested-With": "XMLHttpRequest", "Content-Type": "multipart/form-data" };
			let defaultpearlcolor = [0, 72, 255];
			api.postBody('user/setpearl', { id: state.id, color: defaultpearlcolor }, { body: fd, header: header }, (data) => {
				actions.showSuccess('Your pearl is so beautiful 😍 If you want to change its color do not forget to check your emails in order to activate your account!');
				actions.hidePearl();
			});
		})
	},

	checked: value => state => ({ checkedcookie: true }),
    checkOver: (props) => (state, actions) => {
        actions.checked();
        let contents = nakerscene.create('loginnakerscene', [12, 6, 35, 0.69], () => {
            actions.checkPage(props);
        });
    
        nakermodel = contents.model;
        nakerlight = contents.light;
        nakertext = contents.text;
        nakerheightmap = contents.heightmap;
    
        if (env.mode == 'app') {
            window.Intercom('boot', {
                app_id: 'sz0pojg8',
            });
        }
    },

	clickConnect: () => (state, actions) => {
		if (state.login) actions.tryLogin();
		else actions.trySignup();
	},
	setLoginState: value => state => ({ login: true, name: '', email: '', password: '', error: null, loading: false, success: false }),
	showLogin: value => (state, actions) => {
		actions.setLoginState();
		nakerscene.nakerintale.navigation.moveToPointofView("Login");
	},
	setSignupState: value => state => ({ login: false, name: '', email: '', password: '', error: null, loading: false, success: false }),
	showSignup: value => (state, actions) => {
		actions.setSignupState();
		nakerscene.nakerintale.navigation.moveToPointofView("Signup");
	},

	setName: value => state => ({ name: value, error: null }),
	setEmail: value => state => ({ email: value, error: null }),
	setPassword: value => state => ({ password: value, error: null }),
	setType: value => state => ({ type: value, error: null }),
	setId: value => state => ({ id: value }),

	updateName: (value) => (state, actions) => {
		actions.setName(value);
		let sstep = 1/1000;
		actions.animate((perc) => {
			let density = defaultFogDensity - Math.sqrt(value.length * sstep + perc * sstep);
			density = Math.max(0.2, density);
			nakerscene.nakerintale.environment.setFogDensity(density);
		});
	},

	updateEmail: (value) => (state, actions) => {
		actions.setEmail(value);
		let sstep = 2;
		actions.animate((perc) => {
			let height = Math.sqrt(2 + value.length * sstep + perc * sstep);
			nakerheightmap.setBumpHeight(height);
		});
	},

	updatePassword: (value) => (state, actions) => {
		actions.setPassword(value);
		let istep = defaultLightIntensity * 100;
		actions.animate((perc) => {
			let newi = Math.sqrt(value.length * istep + perc * istep);
			nakerlight.setIntensity(newi);
		});
	},

	saveInterval: value => state => ({ animInterval: value }),
	animate: (funct) => (state, actions) => {
		clearInterval(state.animInterval);
		let i = 0;
		let int = setInterval(() => {
			let perc = Math.pow(i / 5, 2);
			funct(perc);
			if (i == 5) clearInterval(int);
			i++;
		}, 30);
		actions.saveInterval(int);
	},

	updateType: (value) => (state, actions) => {
		let color;
		if (value == 'student') color = [255, 0, 50, 1];
		if (value == 'freelance') color = [191, 80, 143, 1];
		if (value == 'agency') color = [253, 107, 0, 1];
		if (value == 'brand') color = [0, 100, 255, 1];
		nakerlight.setColor(color);
		actions.setType(value);
	},

	showLoader: value => state => ({ loading: true }),
	hideLoader: value => state => ({ loading: false }),
	showError: value => state => ({ error: value, loading: false }),
	showSuccess: value => state => ({ success: value, loading: false }),
	showPearl: value => state => ({ pearl: true }),
	hidePearl: value => state => ({ pearl: false }),

	keypressInput: (e) => (state, actions) => {
		if (e.which == 13) actions.clickConnect();
	},

	tryLogin: () => (state, actions) => {
		if (state.name.length == 0) return actions.showError('Enter a Username please 🙏');
		if (state.password.length == 0) return actions.showError('Enter a Password please 🙏');
		if (state.name.length < 6 || state.password.length < 6) return actions.showError('Username and Password must have 6 characters at least 🖐️+👍');
		nakertext.startAllAnimation();
		api.post('user/login', { name: state.name, password: state.password }, (data) => {
			if (data.success) {
				actions.logUser(data);
			} else {
				nakertext.pauseAnimation();
				actions.showError(data.message + ' 😕');
			}
		});
	},

	logUser: (data) => (state, actions) => {
		if (env.admin && !data.admin) return actions.showError('You must be an admin to access ' + env.mode);
		actions.showSuccess('Hey ! So nice to see you ' + data.name + ', Ready to make some magic? 🧙');
		if (data.pearl != undefined) {
			if (data.pearl.length != 0) {
				// nakermodel.setScale({ x: 0, y: 0, z: 0 });
				nakerscene.updatePearl(data.pearl);
				let color = data.pearlcolor;
				let pearlColor = (color && color.length != 0) ? color : [255, 255, 255];
				nakerscene.setLightsColor(pearlColor);
				nakermodel.pattern.setTextureColor('albedo', pearlColor);
				nakerscene.setLightsIntensity(defaultLightIntensity);
			}
		}
		let origin = api.getQueryParam('origin');
		spyservice.track("Plateform Login", {
		    origin: origin
		});
		
		nakerscene.nakerintale.navigation.moveToPointofView("Connected");
		setTimeout(() => {
			actions.redirect();
		}, 4000);
		var tokencookie = { access_token: data.access_token, refresh_token: data.refresh_token };
		Cookies.set('token', JSON.stringify(tokencookie), { expires: 7 });
	},

	trySignup: () => (state, actions) => {
		if (state.name.length == 0) return actions.showError('Enter a Username please 🙏');
		if (state.password.length == 0) return actions.showError('Enter a Password please 🙏');
		if (state.email.indexOf('@') == -1 || state.email.indexOf('.') == -1) return actions.showError('Uncorrect E-mail');
		if (state.name.length < 6 || state.password.length < 6) return actions.showError('Username and Password must have 6 characters at least 🖐️+👍');
		nakertext.startAllAnimation();
		api.post('user/register', { name: state.name, password: state.password, email: state.email, type: state.type }, (data) => {
			if (data.success) {
				let origin = api.getQueryParam('origin');
				spyservice.alias(data.email);
				spyservice.track("Plateform Signup", {
				    origin: origin
				});
				actions.setId(data.id);
				actions.setPearlEditMode();
			} else {
				nakertext.pauseAnimation();
				actions.showError(data.message + ' 😕');
			}
		});
	},

	setPearlEditMode: () => (state, actions) => {
		actions.resetScene();
		actions.showSuccess('A confirmation email is on its way to your address, once confirmed you will be able to connect. Meanwhile, you can create your pearl 💎');
		actions.showPearl();
		nakerscene.updatePearl();
		nakerlight.setIntensity(defaultLightIntensity);
	}
}

const Login = {};
Login.view = view;
Login.actions = actions;
Login.state = state;

// TODO Terms and Services
// <div class="checkbox-container">
//   <input type="checkbox"></input>
//   <div class="text-checkbox">I agree with the terms of service.</div>
// </div>

// IF LOGIN ISNT WORKING LOCALLY, ENABLE TOKENS ON SERVER'S ENVIRONMENT

module.exports = Login;
