
import { h } from "hyperapp";
import { Link } from "@hyperapp/router";

import Chart from 'chart.js';
import '../../styles/deepsea.sass';

const api = require('../services/api');
const env = require('../services/environment');

const view = (state, actions) => props => {
	return (
		<div class="deepsea-mainpage scrollbar" oncreate={element => actions.getData()}>
			<div class="projects-container" style="margin-top: 75px; margin-left:50px; margin-bottom: 50px">
				<div class="templates-input form-group">
					<input
						type='text'
						placeholder=""
						oninput={e => actions.searchProject(e.target.value)}
					/>
					<span class="label">Search a project!</span>
					<span class="border"></span>
				</div>
				<div class="old_ie_wrapper">
				{state.searchedProjects.length == 0 ? null :
				<table class="fixed_headers">
					<thead>
						<tr>
							<th class="table-name">Name</th>
							<th class="table-id">ID</th>
							<th class="table-view">Views</th>
							<th class="table-link">Link to project</th>
							<th class="table-link">Link to logs</th>
						</tr>
					</thead>
					<tbody>
						{state.searchedProjects.map(project => (
							<tr>
								<td class="table-name">{project.name}</td>
								<td class="table-id">{project._id}</td>
								<td class="table-view">{project.views}</td>
								<td class="table-link">
									<a href={'https://harbor.naker.io/' + project._id} class="linktoproject projectpublished" target='_blank'>Harbor</a>
								</td>
								<td class="table-link">
									<Link class={"linktoproject projectpublished"} to={`/data/projects/` + project._id} onclick={() => { actions.getData(project._id) }}>
										<div>Logs</div>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			}
			</div>
			{!state.project.name ? null :
				<table class="fixed_headers">
					<thead>
						<tr>
							<th class="table-name">Name</th>
							<th class="table-id">ID</th>
							<th class="table-view">Views</th>
							<th class="table-model">Model Size</th>
							<th class="table-name">Designer</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="table-name">{state.project.name}</td>
							<td class="table-id">{state.project._id}</td>
							<td class="table-view">{state.project.views}</td>
							<td class="table-model">{state.project.model_size}</td>
							<td class="table-designer">
									<Link class={"linktoproject projectpublished"} to={`/data/users/` + state.project.designer}>
										<div>{state.project.designer}</div>
									</Link>
							</td>
						</tr>
					</tbody>
				</table>
				}
			</div>
			<div class='projects-container chart-page'>
				<div class="chart-container chart chart-right">
					<canvas id='weeklyViewsChart'></canvas>
				</div>
				<div class="chart-container chart chart-left">
					<canvas id='viewsChart'></canvas>
				</div>
				<div class="chart-container chart chart-right">
					<canvas id='hotspotsChart'></canvas>
				</div>
				<div class="chart-container chart chart-left">
					<canvas id='linksChart'></canvas>
				</div>
				<div class="chart-container chart chart-right">
					<canvas id='publiViewsChart'></canvas>
				</div>
			</div>
		</div>
	)
}

const state = {
	views: 0,
	project: {},
	dataset: {},
	views: [],
	dates: [],
	hotspots: [],
	links: [],
	weeklyViews: [],
	searching: false,
	searchedProjects: [],
	publicationDates: []
}

let searchTimeout;

const actions = {

	getData: (projectId = null) => (state, actions) => {
		api.getToken();
		if (!projectId)
			projectId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
		if (projectId === 'projects' || !projectId)
			return;
		api.get('deepsea/project/', { id: projectId }, (data) => {
			if (data.success) {
				api.disconnect();
			} else {
				console.log(data);
				actions.setData(data);
			}
		});
	},

	setData: data => (state, actions) => {
		actions.setProject(data.project);
		actions.createDailyDataset(data.logs);
		actions.createPublicationDataset(data.backups);
		actions.createWeeklyDataset();
	},

	convertDate: date => (state, actions) => {
		let d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear()
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('/');
	},

	setProject: value => state => ({ project: value }),
	setDataset: value => state => ({ dataset: value }),
	setViews: value => state => ({ views: value }),
	setDates: value => state => ({ dates: value }),
	setHotspots: value => state => ({ hotspots: value }),
	setLinks: value => state => ({ links: value }),
	setWeeklyViews: value => stae => ({ weeklyViews: value }),
	setSearching: value => state => ({ searching: value }),
	setSearchedProjects: value => state => ({ searchedProjects: value }),

	searchProject: (value) => (state, actions) => {
		if (searchTimeout) clearTimeout(searchTimeout);
		actions.setSearching(true);
		searchTimeout = setTimeout(() => {
			api.get('deepsea/search', { name: value }, (data) => {
				actions.setSearching(false);
				if (data.success != undefined) {
				} else {
					actions.setSearchedProjects(data);
				}
			});
		}, 1000);
	},

	createWeeklyDataset: () => (state, actions) => {
		let day = 1000 * 60 * 60 * 24;
		let beginning = new Date(state.dates[0]).getTime();
		let prevWeek = 0;
		let prevWeekBeginning = beginning;
		let weeklyViews = 0;
		let weeksArray = [];
		for (let i = 0; i < state.dates.length; i++) {
			let date = new Date(state.dates[i]).getTime();
			let delta = Math.round((date - beginning) / day);
			if (prevWeek + 1 <= delta / 7) {
				weeksArray.push({ date: actions.convertDate(new Date(prevWeekBeginning)), views: weeklyViews});
				prevWeekBeginning = date;
				weeklyViews = 0;
				prevWeek += 1;
			}
			weeklyViews += state.views[i];
		}
		weeksArray.push({ date: actions.convertDate(new Date(prevWeekBeginning)), views: weeklyViews});
		actions.setWeeklyViews(weeksArray);
		actions.createWeeklyCanvas();
	},


	createPublicationDataset: (publicationDates) => (state, actions) => {
		if (publicationDates[0] === null)
			publicationDates.shift();
		publicationDates = publicationDates.map(e => actions.convertDate(e));
		let publicationsArray = [];
		let publicationViews = 0;
		let c = 0;
		for (let i = 0; i < state.dates.length; i++) {
			let date = new Date(state.dates[i]);
			let pDate = new Date(publicationDates[c]);
			if (date.getTime() >= pDate.getTime()) {
				c += 1;
				publicationsArray.push({ date: actions.convertDate(pDate), views: publicationViews });
				publicationViews = 0;
			}
			publicationViews += state.views[i];
		}
		publicationsArray.push({ date: actions.convertDate(new Date()), views: publicationViews });
		actions.createPublicationCanvas(publicationsArray);
	},

	createDailyDataset: data => (state, actions) => {
		let dates = [];
		let views = [];
		let hotspots = [];
		let links = [];
		data.logs.forEach((log) => {
			log.clickedLinks.forEach((link) => {
				for (let i = 0; i < links.length; i++) {
						if (links[i].title === link.title) {
							links[i].clicks = Number(links[i].clicks) + Number(link.clicks);
							return;
						}
					}
				links.push({tag: link.tag, clicks: Number(link.clicks), url: link.url});
			});
			log.clickedHotspots.forEach((hotspot) => {
				for (let i = 0; i < hotspots.length; i++) {
						if (hotspots[i].title === hotspot.title) {
							hotspots[i].clicks = Number(hotspots[i].clicks) + Number(hotspot.clicks);
							return;
						}
					}
				hotspots.push({title: hotspot.title, clicks: Number(hotspot.clicks)});
			});
			for (let i = 0; i < dates.length; i++) {
				if (dates[i] === actions.convertDate(log.date)) {
					views[i] += 1;
					return;
				}
			}
			dates.push(actions.convertDate(log.date));
			views.push(1);
		});
		actions.setViews(views);
		actions.setDates(dates);
		actions.setLinks(links);
		actions.setHotspots(hotspots);
		actions.createViewsCanvas();
		actions.createHotspotsCanvas();
		actions.createLinkCanvas();
	},

	createPublicationCanvas: (array) => () => {
		let dates = array.map(item => item.date);
		let views = array.map(item => item.views);
		var data = {
			labels: dates,
			datasets: [{
				label: "Number of views per publication",
				backgroundColor: "#7A8EE8",
				borderColor: "#7A8EE8",
				borderWidth: 2,
				hoverBackgroundColor: "#ADBEFF",
				hoverBorderColor: "rgba(255,255,255,1)",
				data: views,
			}]
		};
		var option = {
			responsive: true,
			scales: {
				yAxes: [{
					stacked: true,
					gridLines: {
						display: true,
						color: "rgba(255,99,132,0.2)"
					}
				}],
				xAxes: [{
					gridLines: {
						display: false
					}
				}]
			}
		};
		Chart.Line('publiViewsChart', {
			options: option,
			data: data
		});
	},

	createWeeklyCanvas: () => (state, actions) => {
		let dates = state.weeklyViews.map(item => item.date);
		let views = state.weeklyViews.map(item => item.views);
		var data = {
			labels: dates,
			datasets: [{
				label: "Number of views per week",
				backgroundColor: "#7A8EE8",
				borderColor: "#7A8EE8",
				borderWidth: 2,
				hoverBackgroundColor: "#ADBEFF",
				hoverBorderColor: "rgba(255,255,255,1)",
				data: views,
			}]
		};
		var option = {
			responsive: true,
			scales: {
				yAxes: [{
					stacked: true,
					gridLines: {
						display: true,
						color: "rgba(255,99,132,0.2)"
					}
				}],
				xAxes: [{
					gridLines: {
						display: false
					}
				}]
			}
		};
		Chart.Line('weeklyViewsChart', {
			options: option,
			data: data
		});
	},

	createLinkCanvas: () => (state, actions) => {
		let tags = state.links.map(item => item.tag);
		let clicks = state.links.map(item => item.clicks);
		let url = state.links.map(item => item.url);
		var data = {
			labels: tags,
			datasets: [{
				label: "Number of times a link was clicked",
				backgroundColor: "#7A8EE8",
				borderColor: "rgba(255,255,255,1)",
				borderWidth: 2,
				hoverBackgroundColor: "#ADBEFF",
				hoverBorderColor: "rgba(255,255,255,1)",
				data: clicks,
			}]
		};
		var option = {
			responsive: true,
			scales: {
				yAxes: [{
					stacked: true,
					gridLines: {
						display: true,
						color: "rgba(255,99,132,0.2)"
					}
				}],
				xAxes: [{
					gridLines: {
						display: false
					}
				}]
			}
		};
		Chart.Bar('linksChart', {
			options: option,
			data: data
		});
	},

	createHotspotsCanvas: () => (state, actions) => {
		let titles = state.hotspots.map(item => item.title);
		let clicks = state.hotspots.map(item => item.clicks);
		var data = {
			labels: titles,
			datasets: [{
				label: "Number of times a hotspot was clicked",
				backgroundColor: "#7A8EE8",
				borderColor: "rgba(255,255,255,1)",
				borderWidth: 2,
				hoverBackgroundColor: "#ADBEFF",
				hoverBorderColor: "rgba(255,255,255,1)",
				data: clicks,
			}]
		};
		var option = {
			responsive: true,
			scales: {
				yAxes: [{
					stacked: true,
					gridLines: {
						display: true,
						color: "rgba(255,99,132,0.2)"
					}
				}],
				xAxes: [{
					gridLines: {
						display: false
					}
				}]
			}
		};
		Chart.Bar('hotspotsChart', {
			options: option,
			data: data
		});
	},

	createViewsCanvas: () => (state, actions) => {
		var data = {
			labels: state.dates,
			datasets: [{
				label: "Number of views",
				backgroundColor: "#7A8EE8",
				borderColor: "rgba(255,255,255,1)",
				borderWidth: 2,
				hoverBackgroundColor: "#ADBEFF",
				hoverBorderColor: "rgba(255,255,255,1)",
				data: state.views,
			}]
		};
		var option = {
			responsive: true,
			scales: {
				yAxes: [{
					stacked: true,
					gridLines: {
						display: true,
						color: "rgba(255,99,132,0.2)"
					}
				}],
				xAxes: [{
					gridLines: {
						display: false
					}
				}]
			}
		};

		Chart.Line('viewsChart', {
			options: option,
			data: data
		});
	},

}

const Data = {};
Data.view = view;
Data.state = state;
Data.actions = actions;

module.exports = Data;
